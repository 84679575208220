import { Controller } from 'stimulus'; 
import 'owl.carousel';

export default class extends Controller {
  connect() {
    const numberOfSlides = parseInt(this.element.dataset.numberOfSlides || '1');
    const numberOfSlidesMobile = parseInt(this.element.dataset.numberOfSlidesMobile) || numberOfSlides;
    const stagePadding = parseInt(this.element.dataset.stagePadding || '0');

    var options = {
      loop: true,
      autoplay: this.element.dataset.autoplay === '1',
      autoplayHoverPause: true,
      nav: false,
      mouseDrag: false,
      touchDrag: false,
      stagePadding: stagePadding,
      smartSpeed: 450,
      responsive: {
        0 : {
          items : numberOfSlidesMobile
        },
        // breakpoint from 480 up
        768 : {
          items : numberOfSlides
        },
      }
    }

    if (this.element.dataset.fadeInOut === '1') {
      options = { ...options, animateOut: 'fadeOut' };
    }

    $(this.element).owlCarousel(options);
  }

  teardown() {
    $(this.element).owlCarousel().owlCarousel('destroy');
  }
}