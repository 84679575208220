import { Controller } from "stimulus";
import { getURL } from "../shared/utils";
import { fr } from "date-fns/locale";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import startOfMonth from "date-fns/startOfMonth";
import endOfWeek from "date-fns/endOfWeek";
import endOfMonth from "date-fns/endOfMonth";
import eachDayOfInterval from "date-fns/eachDayOfInterval";
import format from "date-fns/format";
import compareAsc from "date-fns/compareAsc";
import isSameMonth from "date-fns/isSameMonth";
import addMonths from "date-fns/addMonths";
import subMonths from "date-fns/subMonths";

export default class extends Controller {
  static targets = ["monthName", "weeksRow"];

  builDay(day, date) {
    var dayElement = document.createElement("DIV");
    var linkElement = document.createElement("A");
    linkElement.setAttribute("href", getURL(day, day));
    linkElement.textContent = format(day, "d");
    linkElement.classList.add("picker-item");

    if (!isSameMonth(day, date)) linkElement.classList.add("inverted-contrast");

    if (
      compareAsc(day, this.startAt) === 1 &&
      compareAsc(day, this.endAt) === -1
    )
      linkElement.classList.add("active");
    if (compareAsc(day, this.startAt) === 0)
      linkElement.classList.add("active", "first-active");
    if (compareAsc(day, this.endAt) === 0)
      linkElement.classList.add("active", "last-active");

    dayElement.appendChild(linkElement);

    return dayElement;
  }

  buildGrid(date) {
    var dailyElements = [];
    var start = startOfWeek(startOfMonth(date), { weekStartsOn: 1 });
    var end = endOfWeek(endOfMonth(date), { weekStartsOn: 1 });

    eachDayOfInterval({ start, end }).forEach((day, index) => {
      var dayElement = this.builDay(day, date);
      dailyElements.push(dayElement);

      if ((index + 1) % 7 === 0) {
        let weekElement = document.createElement("DIV");
        weekElement.classList.add("picker-row");
        weekElement.append(...dailyElements);
        this.weeksRowTarget.appendChild(weekElement);
        dailyElements = [];
      }
    });
  }

  selectMonth() {
    const startAt = startOfMonth(this.currentMonth);
    const endAt = endOfMonth(this.currentMonth);
    window.location.href = getURL(startAt, endAt);
  }

  setCurrentMonth(newDate) {
    this.currentMonth = newDate;
    this.weeksRowTarget.textContent = "";
    this.monthNameTarget.innerText = format(this.currentMonth, "LLLL yyyy", {
      locale: fr,
    });
    this.buildGrid(this.currentMonth);
  }

  nextMonth() {
    this.setCurrentMonth(addMonths(this.currentMonth, 1));
  }

  previousMonth() {
    this.setCurrentMonth(subMonths(this.currentMonth, 1));
  }

  connect() {
    this.startAt = parse(
      this.element.dataset.rangeStartAt,
      "yyyy-MM-dd",
      new Date()
    );
    this.endAt = parse(
      this.element.dataset.rangeEndAt,
      "yyyy-MM-dd",
      new Date()
    );
    this.currentMonth = this.startAt;
    this.weeksRowTarget.textContent = "";
    this.buildGrid(this.currentMonth);
  }
}
