import { Controller } from 'stimulus'; 
import anime from 'animejs/lib/anime.es.js';
import { createCookie, readCookie } from '../shared/utils';

export default class extends Controller {
  close() {
    anime({
      targets: this.element,
      marginTop: -this.element.offsetHeight,
      duration: 1000,
      easing: 'easeInOutExpo',
      complete: () => {
        createCookie('hideTopAlert', '1', 1);
        this.element.classList.add('d-none');
      }
    });
  }
  connect() {
    const isHiddenFromCookie = readCookie('hideTopAlert') === '1';
    if (!isHiddenFromCookie) {
      this.element.classList.remove('d-none');
    };    
  }
}
