import format from 'date-fns/format';

/**
 * http://stackoverflow.com/a/10997390/11236
 */
export const updateURLParameter = (url, param, paramVal) => {
  var newAdditionalURL = '';
  var tempArray = url.split('?');
  var baseURL = tempArray[0];
  var additionalURL = tempArray[1];
  var temp = '';
  if (additionalURL) {
    tempArray = additionalURL.split('&');
    for (var i = 0; i < tempArray.length; i++) {
      if (tempArray[i].split('=')[0] != param) {
          newAdditionalURL += temp + tempArray[i];
          temp = '&';
      }
    }
  }
  var rowsTxt = temp + '' + param + '=' + paramVal;
  return baseURL + '?' + newAdditionalURL + rowsTxt;
}

export const getURL = (startAt, endAt) => {
  var url = updateURLParameter(window.location.href, 'from', format(startAt, 'yyyy-MM-dd'));
  url = updateURLParameter(url, 'to', format(endAt, 'yyyy-MM-dd'));
  return url;
}

export const createCookie = (name, value, days) => {
  var expires;
  if (days) {
    var date = new Date();
    date.setTime(date.getTime()+(days*24*60*60*1000));
    expires = "; expires="+date.toGMTString();
  }
  else {
    expires = "";
  }
  document.cookie = name+"="+value+expires+"; path=/";
}

export const readCookie = (name) => {
  var nameEQ = name + "=";
  var ca = document.cookie.split(';');
  for(var i=0;i < ca.length;i++) {
    var c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1,c.length);
    }
    if (c.indexOf(nameEQ) === 0) {
      return c.substring(nameEQ.length,c.length);
    }
  }
  return null;
}

export const eraseCookie = (name) => {
  createCookie(name, '', -1);
}