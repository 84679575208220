import { Controller } from "stimulus";
import { getURL } from "../shared/utils";
import parse from "date-fns/parse";
import set from "date-fns/set";
import compareAsc from "date-fns/compareAsc";
import subDays from "date-fns/subDays";
import addMonths from "date-fns/addMonths";
import startOfMonth from "date-fns/startOfMonth";
import endOfMonth from "date-fns/endOfMonth";
import subYears from "date-fns/subYears";
import addYears from "date-fns/addYears";
import getYear from "date-fns/getYear";

export default class extends Controller {
  static targets = ["seasonName", "monthLink"];

  previousSeason() {
    this.currentSeason = subYears(this.currentSeason, 1);
    this.buildMonthLinks(this.currentSeason);
  }

  nextSeason() {
    this.currentSeason = addYears(this.currentSeason, 1);
    this.buildMonthLinks(this.currentSeason);
  }

  connect() {
    this.startAt = parse(
      this.element.dataset.startAt,
      "yyyy-MM-dd",
      new Date()
    );
    this.endAt = parse(this.element.dataset.endAt, "yyyy-MM-dd", new Date());
    this.currentSeason = this.element.dataset.currentStartAt
      ? parse(this.element.dataset.currentStartAt, "yyyy-MM-dd", new Date())
      : this.startAt;

    if (this.currentSeason.getMonth() < 8)
      this.currentSeason = subYears(this.currentSeason, 1);

    this.currentSeason = set(this.currentSeason, { month: 8, date: 1 });
    this.buildMonthLinks(this.currentSeason);
  }

  buildMonthLinks(date) {
    // update the season name + url
    this.seasonNameTarget.innerHTML = `Saison ${getYear(date)}-${
      getYear(date) + 1
    }`;
    this.seasonNameTarget.setAttribute(
      "href",
      getURL(date, subDays(addYears(this.currentSeason, 1), 1))
    );

    // add the right classes + url to each month of the current season
    this.monthLinkTargets.forEach((monthLink, index) => {
      let linkStartAt = addMonths(date, index);
      let linkEndAt = endOfMonth(linkStartAt);

      monthLink.dataset.startAt = linkStartAt;
      monthLink.dataset.endAt = linkEndAt;

      monthLink.classList.remove("active", "first-active", "last-active");

      if (compareAsc(linkStartAt, this.startAt) === 0)
        monthLink.classList.add("active", "first-active");

      if (compareAsc(linkStartAt, startOfMonth(this.endAt)) === 0)
        monthLink.classList.add("active", "last-active");

      if (
        compareAsc(linkStartAt, this.startAt) === 1 &&
        compareAsc(linkStartAt, this.endAt) === -1
      )
        monthLink.classList.add("active");

      monthLink.setAttribute("href", getURL(linkStartAt, linkEndAt));
    });
  }
}
